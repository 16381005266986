import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  backendApi,
  CreateCounterpartyRequest,
} from "../../backend/backend-api";
import { CacheKeys } from "../../common-ui/cacheKeys";

export const useCreateCounterpartyMutation = (onSuccess: () => void) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (variables: CreateCounterpartyRequest) =>
      backendApi.createCounterparty(variables),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: CacheKeys.companies });
      onSuccess();
    },
  });
};
