import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  backendApi,
  UpdateCounterpartyRequest,
} from "../../backend/backend-api";
import { CacheKeys } from "../../common-ui/cacheKeys";

export const useUpdateCounterpartyMutation = (onSuccess: () => void) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (variables: UpdateCounterpartyRequest) =>
      backendApi.updateCounterparty(variables),
    onSuccess: async (variables: UpdateCounterpartyRequest) => {
      await queryClient.invalidateQueries({
        queryKey: CacheKeys.forCounterparties(variables.counterparty.companyId),
      });
      onSuccess();
    },
  });
};
