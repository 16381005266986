import { Route, Routes } from "react-router-dom";
import { Profile } from "./profile/Profile";
import { Settings } from "./settings/Settings";
import { Main } from "./main/Main";

export const Pages = () => {
  return (
    <Routes>
      <Route path="/" element={<Main />}></Route>
      <Route path="/profile" element={<Profile />}></Route>
      <Route path="/settings" element={<Settings />}></Route>
    </Routes>
  );
};
