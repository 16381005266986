import { useQuery } from "@tanstack/react-query";
import { CacheKeys } from "../../common-ui/cacheKeys";
import { backendApi } from "../../backend/backend-api";
import _ from "lodash";
import { Counterparty } from "../types";
import { useCurrentCompany } from "../../company/CompanyContext";

export const useFetchedCounterparties = () => {
  const { currentCompany } = useCurrentCompany();
  return useQuery({
    queryKey: CacheKeys.forCounterparties(currentCompany.id),
    queryFn: async (): Promise<Counterparty[]> =>
      _((await backendApi.getCounterparties()).counterparties)
        .sortBy((x) => x.name)
        .value(),
    refetchInterval: 240_000,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: true,
    staleTime: 120_000,
  });
};
