import React, { useState } from "react";
import { TextField, Button, Box, Chip } from "@mui/material";
import { Counterparty } from "./types";

export const CounterpartyForm = (props: {
  counterparty: Counterparty;
  onCounterpartyChanged: (counterparty: Counterparty) => void;
}) => {
  const { counterparty, onCounterpartyChanged } = props;
  const [aliasInput, setAliasInput] = useState("");

  const handleAddAlias = () => {
    if (aliasInput.trim() !== "") {
      onCounterpartyChanged({
        ...counterparty,
        aliases: [...counterparty.aliases, aliasInput.trim()],
      });
      setAliasInput("");
    }
  };

  const handleDeleteAlias = (aliasToDelete: string) => {
    onCounterpartyChanged({
      ...counterparty,
      aliases: counterparty.aliases.filter((x) => x !== aliasToDelete),
    });
  };

  return (
    <Box component="form" noValidate autoComplete="off">
      <TextField
        fullWidth
        margin="normal"
        label="Counterparty Name"
        value={counterparty.name}
        onChange={(e) =>
          onCounterpartyChanged({ ...counterparty, name: e.target.value })
        }
      />
      <TextField
        fullWidth
        margin="normal"
        label="VAT Number"
        value={counterparty.vatNumber || ""}
        onChange={(e) =>
          onCounterpartyChanged({
            ...counterparty,
            vatNumber: e.target.value ? e.target.value : null,
          })
        }
      />
      <TextField
        fullWidth
        margin="normal"
        label="Add Alias"
        value={aliasInput}
        onChange={(e) => setAliasInput(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            handleAddAlias();
          }
        }}
      />
      <Button
        variant="contained"
        onClick={handleAddAlias}
        sx={{ mt: 1, mb: 2 }}
      >
        Add Alias
      </Button>
      <Box>
        {counterparty.aliases.map((alias, index) => (
          <Chip
            key={index}
            label={alias}
            onDelete={() => handleDeleteAlias(alias)}
            sx={{ mr: 1, mb: 1 }}
          />
        ))}
      </Box>
    </Box>
  );
};
