import { Counterparty } from "./types";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { CounterpartyForm } from "./CounterpartyForm";
import Button from "@mui/material/Button";
import { useState } from "react";
import { useCreateCounterpartyMutation } from "./hooks/useCreateCounterpartyMutation";
import { useUpdateCounterpartyMutation } from "./hooks/useUpdateCounterpartyMutation";

export const CounterpartyDialog = (props: {
  counterparty: Counterparty;
  open: boolean;
  onClose: () => void;
}) => {
  const { counterparty, open, onClose } = props;
  const [editedCounterparty, setEditedCounterparty] = useState(counterparty);

  const { mutate: createCounterparty } = useCreateCounterpartyMutation(onClose);
  const { mutate: updateCounterparty } = useUpdateCounterpartyMutation(onClose);

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        {counterparty.id
          ? `Edit counterparty - ${counterparty.name}`
          : "New counterparty"}
      </DialogTitle>
      <DialogContent>
        <CounterpartyForm
          counterparty={editedCounterparty}
          onCounterpartyChanged={setEditedCounterparty}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            if (counterparty.id) {
              updateCounterparty({ counterparty: editedCounterparty });
            } else {
              createCounterparty(editedCounterparty);
            }
          }}
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
