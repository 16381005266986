/* eslint-disable react/jsx-pascal-case */
import { useFetchedCounterparties } from "./hooks/useFetchedCounterparties";
import { useMemo, useState } from "react";
import {
  MaterialReactTable,
  MRT_ColumnDef,
  useMaterialReactTable,
} from "material-react-table";
import { PanelSpinner } from "../common-ui/Spinner";
import { Counterparty } from "./types";
import { Box, Button } from "@mui/material";
import { Add } from "@mui/icons-material";
import { CounterpartyDialog } from "./CounterpartyDialog";
import { useCurrentCompany } from "../company/CompanyContext";

export const Counterparties = () => {
  const { data: counterparties } = useFetchedCounterparties();
  const columns = useMemo<MRT_ColumnDef<Counterparty>[]>(
    () => [
      {
        header: "Name",
        accessorKey: "name",
        enableHiding: false,
      },
      {
        header: "VAT Number",
        accessorKey: "vatNumber",
      },
    ],
    [],
  );

  const counterpartiesOrEmpty = counterparties ?? [];

  const [newCounterparty, setNewCounterparty] = useState<Counterparty | null>(
    null,
  );

  const { currentCompany } = useCurrentCompany();

  const table = useMaterialReactTable({
    columns,
    data: counterpartiesOrEmpty,
    enableGlobalFilter: false,
    enablePagination: false,
    editDisplayMode: "modal",
    enableEditing: true,
    renderTopToolbarCustomActions: () => (
      <Button
        startIcon={<Add />}
        onClick={() =>
          setNewCounterparty({
            companyId: currentCompany.id,
            id: "",
            name: "",
            vatNumber: null,
            vatNumberVerified: null,
            aliases: [],
            countryCode: "",
          })
        }
      >
        New counterparty
      </Button>
    ),
    renderEditRowDialogContent: ({ row, table }) => {
      return (
        <CounterpartyDialog
          counterparty={row.original}
          open={true}
          onClose={() => {
            table.setEditingRow(null);
          }}
        />
      );
    },
  });

  if (!counterparties) return <PanelSpinner />;
  return (
    <>
      <Box>
        <MaterialReactTable table={table} />
      </Box>
      {newCounterparty && (
        <CounterpartyDialog
          counterparty={newCounterparty}
          open={true}
          onClose={() => setNewCounterparty(null)}
        />
      )}
    </>
  );
};
