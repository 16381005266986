import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { useState } from "react";
import { Counterparties } from "../counterparty/Companies";
import { Documents } from "../document/Documents";

const DOCUMENTS_TAB = "documents";
const COUNTERPARTIES_TAB = "counterparties";

export const Main = () => {
  const [tabValue, setTabValue] = useState(DOCUMENTS_TAB);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", height: "100%", typography: "body1" }}>
      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange}>
            <Tab label="Documents" id="tab-documents" value={DOCUMENTS_TAB} />
            <Tab
              label="Counterparties"
              id="tab-counterparties"
              value={COUNTERPARTIES_TAB}
            />
          </TabList>
        </Box>
        <TabPanel
          sx={{ height: "calc(100% - 48px)", padding: 0 }}
          value={DOCUMENTS_TAB}
        >
          <Documents />
        </TabPanel>
        <TabPanel value={COUNTERPARTIES_TAB}>
          <Counterparties />
        </TabPanel>
      </TabContext>
    </Box>
  );
};
