import { InvoiceDirection } from "../invoice/types";

export const CacheKeys = {
  companies: ["companies"],

  forCounterparties: (companyId: string) => ["counterpartioes", companyId],

  forDirectoryItems: (directoryItemId: string) => [
    "directory",
    directoryItemId,
    "items",
  ],
  forFileItemContent: (fileItemId: string) => ["file", fileItemId, "content"],

  forInvoiceAnalysis: (
    fileItemId: string,
    direction: InvoiceDirection | null,
    mimeType: string | null,
  ) => ["invoice-analysis", fileItemId, direction ?? "", mimeType ?? ""],
};
